/* Banner.css */
.banner {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.banner .logo {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  opacity: 0.9;
  transition: all 0.5s ease;
  width: 60%;
  height: auto;
}

.banner h1 {
  font-size: xxx-large;
}

.banner h2 {
  font-size: xx-large;
}

.banner.scrolled .logo {
  position: absolute;
}

.banner h1,
.banner h2 {
  z-index: 2;
  margin: 0;
  animation: slideUp 3s ease-in-out;
  position: relative;
  z-index: 2;
}

/* Animation pour faire défiler le texte vers le haut */
@keyframes slideUp {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Styles spécifiques pour les petits écrans */
@media (max-width: 600px) {
  .banner .logo {
    width: 90%;
  }

  .banner h1 {
    font-size: 1.8rem;
  }

  .banner h2 {
    font-size: 1.2rem;
  }
}
