.Recrut,
.Footer {
  position: relative;
  z-index: 2; /* S'assure que ce contenu passe par-dessus le logo */
  background-color: rgba(
    255,
    255,
    255,
    0.9
  ); /* Optionnel : arrière-plan partiellement opaque pour meilleur contraste */
}
