/* Recrut.css */
.recrut {
  background-color: #ffffff; /* Arrière-plan blanc pour un contraste avec la page */
  padding: 30px 20px; /* Espacement autour du texte */
  border-radius: 8px; /* Bords arrondis pour un aspect moderne */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Légère ombre pour un effet de profondeur */
  max-width: 620px; /* Limite la largeur pour garder le texte lisible */
  margin: 40px auto; /* Centre le contenu et ajoute un espacement */
  text-align: center; /* Centre le texte */
}

.recrut p {
  font-size: 1.2rem; /* Taille de police plus grande pour le texte */
  line-height: 1.5; /* Interligne pour une meilleure lisibilité */
  color: #333; /* Couleur du texte */
  margin-bottom: 20px; /* Espacement sous le paragraphe */
}

.recrut a {
  display: inline-block; /* Permet d'appliquer des marges et des padding comme un bouton */
  padding: 12px 25px; /* Espacement interne pour ressembler à un bouton */
  font-size: 1rem; /* Taille de police du lien */
  color: black; /* Couleur du texte sur le bouton */
  background-color: #ffbd00; /* Couleur de fond du bouton (bleu) */
  border-radius: 5px; /* Bords arrondis pour le bouton */
  text-decoration: none; /* Retire la sous-ligne */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Transition pour le hover */
}

.recrut a:hover {
  transform: translateY(-3px); /* Effet de levée au survol */
}

.recrut a:active {
  transform: translateY(0); /* Enlève l'effet de levée lorsqu'on clique */
}
