/* Presentation.css */
.presentation {
  background-color: #f9f9f9; /* Couleur d'arrière-plan douce pour contraster avec le reste de la page */
  background: linear-gradient(to bottom, #ffffff, #ffbd00);
  padding: 40px 20px; /* Espacement autour du contenu */
  border-radius: 8px; /* Bords arrondis pour un effet plus doux */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Légère ombre pour un effet de profondeur */
  max-width: 800px; /* Limite la largeur pour un texte plus lisible */
  margin: 40px auto; /* Centre le contenu horizontalement et ajoute un espacement en haut et en bas */
}

.presentation-header h1 {
  max-width: 400px;
  padding-bottom: 20px;
  border-bottom: solid 3px black;
  font-size: 2.5rem; /* Taille de police plus grande pour le titre principal */
  color: #333; /* Couleur du texte plus sombre pour un meilleur contraste */
  margin-bottom: 20px; /* Espacement sous le titre */
}

.presentation p {
  font-size: 1.125rem; /* Taille de police légèrement plus grande pour le texte principal */
  line-height: 1.6; /* Meilleure lisibilité avec un interligne plus grand */
  color: #555; /* Couleur du texte légèrement adoucie */
}

.user {
  display: flex;
  flex-direction: column; /* Change le layout en colonne pour les petits écrans */
  gap: 20px; /* Espacement entre les sections */
  margin-top: 20px;
}

.organisateur,
.prestataire {
  background-color: #fff; /* Fond blanc pour chaque section */
  padding: 20px;
  border-radius: 8px; /* Bords arrondis pour correspondre à l'ensemble du conteneur */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Légère ombre pour chaque section */
}

.organisateur h2,
.prestataire h2 {
  font-size: 1.5rem; /* Taille de police plus grande pour les sous-titres */
  color: #333; /* Couleur du texte sombre */
  margin-bottom: 10px; /* Espacement sous le sous-titre */
}

.organisateur p,
.prestataire p {
  font-size: 1rem; /* Taille de police normale pour le texte de chaque section */
  color: #555; /* Couleur du texte */
}

@media (min-width: 758px) {
  .user {
    flex-direction: row; /* Change le layout en rangée pour les écrans plus larges */
    justify-content: space-between;
  }

  .organisateur,
  .prestataire {
    flex: 1;
    margin: 0 10px; /* Espacement latéral pour chaque section */
  }
}
