/* Footer.css */
.footer {
  background-color: #333; /* Fond sombre pour un contraste marqué avec le reste de la page */
  color: #fff; /* Texte en blanc pour être lisible sur le fond sombre */
  padding: 40px 20px; /* Espacement interne pour aérer le contenu */
  text-align: center; /* Centrer tout le contenu */
}

.reseau {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; /* Espacement sous les réseaux sociaux */
  font-size: 1.2rem; /* Taille de police légèrement plus grande pour le texte de réseaux sociaux */
}

.reseau a {
  margin-left: 10px; /* Espacement à gauche de l'icône Instagram */
}

.reseau img {
  width: 40px; /* Taille ajustée de l'icône Instagram pour une meilleure proportion */
  transition: transform 0.3s ease; /* Transition pour effet hover */
}

.reseau img:hover {
  transform: scale(1.2); /* Agrandissement de l'icône au survol */
}

.contact {
  margin-bottom: 30px; /* Espacement sous la section contact */
}

.contact p {
  font-size: 1.1rem; /* Taille de police pour le texte de contact */
  margin-bottom: 10px; /* Espacement sous le paragraphe */
}

.contact button {
  padding: 10px 20px; /* Espacement interne pour le bouton */
  font-size: 1rem; /* Taille de police du bouton */
  color: #333; /* Couleur du texte du bouton */
  background-color: #fff; /* Fond blanc pour contraster avec le footer sombre */
  border: none; /* Retire les bordures */
  border-radius: 5px; /* Bords arrondis pour un bouton plus moderne */
  cursor: pointer; /* Changement du curseur au survol */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Transition pour le hover */
}

.contact button:hover {
  background-color: #ddd; /* Fond plus sombre au survol */
  transform: translateY(-2px); /* Légère levée au survol */
}

.copyright {
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem; /* Taille de police légèrement réduite pour la section copyright */
  border-top: 1px solid #444; /* Légère ligne de séparation au-dessus */
  padding-top: 20px; /* Espacement au-dessus du texte */
  margin-top: 20px; /* Espacement au-dessus de la ligne */
}

.copyright-left,
.copyright-right {
  color: #bbb; /* Couleur gris clair pour le texte de copyright */
}

@media (max-width: 600px) {
  .copyright {
    flex-direction: column; /* Change l'alignement des éléments pour les petits écrans */
    align-items: center; /* Centre les éléments en colonne */
  }

  .copyright-left,
  .copyright-right {
    margin-bottom: 10px; /* Espacement entre les deux lignes de texte */
  }
}
